import { XIcon } from "@heroicons/react/outline";
import { Modal, Progress } from "antd";

import React from "react";
import ButtonDefault, { ButtonVariants } from "../../../shared/basic/button";
import { onBoardingStepsHeadingData } from "../../../../utils/data";
import { useRecoilState } from "recoil";
import { onBoardingSteps } from "../../../../atoms/onBoardingSteps";
import { ShouldRender } from "../../../shared/basic/ShouldRender";
import Step1 from "./Step1";
import PaymentManagement from "../../../ProfileComp/PaymentManagement";
import { triggerHandleSubmitStep2 } from "../../../../atoms/triggerHandleSubmitStep2";
import FinalStep from "./FinalStep";
import clsx from "clsx";
import { getUser } from "../../../../services/user";
import { UserData } from "../../../../utils/interfaces/user";
import { notify } from "../../../shared/basic/notify";
import { user } from "../../../../atoms/toggleTheme/user";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

interface Props {
  visible: boolean;
  closeModal: () => void;
}

const OnboardingModal: React.FC<Props> = ({ closeModal, visible }) => {
  const [onboardingStepsValue, setOnboardingStepsValue] =
    useRecoilState(onBoardingSteps);
  const [triggerHandleSubmitStep2Value, setTriggerHandleSubmitStep2Value] =
    useRecoilState(triggerHandleSubmitStep2);
  const currentItem = onBoardingStepsHeadingData[onboardingStepsValue];
  const [userData, setUserData] = useRecoilState<UserData | undefined>(user);
  const screens = useBreakpoint();
  const getUserDetails = async () => {
    try {
      const data = await getUser();

      setUserData({
        ...(userData as UserData),
        is_new_user: data?.data?.data?.affiliate_user?.is_new_user,
      });
    } catch (error: any) {
      notify("You are not authenticated", "error");
    }
  };

  const handleClose = () => {
    setOnboardingStepsValue(1);
    closeModal();
  };

  return (
    <Modal
      open={visible}
      maskClosable={false}
      closable={false}
      footer={null}
      mask={true}
      width={550}
      className="customModalPaddingZero "
      style={
        !screens?.md
          ? { top: 0, left: 0, right: 0, bottom: 0, margin: 0, padding: 0 }
          : {}
      }
      onCancel={handleClose}
    >
      <div
        className={clsx(
          !screens?.md && "min-h-[90vh] overflow-hidden justify-between",
          "flex flex-col gap-3  p-5"
        )}
      >
        <span className="mb-2">
          <div
            className={clsx(
              onboardingStepsValue !== 3 ? "border-b-2 border-b-[#303030]" : "",
              " flex gap-y-3  flex-col pb-4"
            )}
          >
            <div
              className={clsx(
                onboardingStepsValue !== 3
                  ? " items-center"
                  : "items-end justify-end",
                "flex gap-x-4 "
              )}
            >
              <ShouldRender check={onboardingStepsValue !== 3}>
                <Progress
                  percent={((onboardingStepsValue - 1) * 100) / 2}
                  className="customProcessBar"
                  showInfo={false}
                  strokeColor={{ from: "#FF5612", to: "#FFFFF" }}
                />
              </ShouldRender>

              <span
                onClick={handleClose}
                className="bg-[#303030] cursor-pointer group flex justify-center items-center rounded-full p-1"
              >
                <XIcon className="w-4 h-4 group-hover:text-textGrayColor text-[#9A9A9A]" />
              </span>
            </div>
            <div>
              <p className="text-lg">{currentItem?.heading}</p>
              <p className="text-sm text-textGrayColor">
                {currentItem?.subheading}
              </p>
            </div>
          </div>
          <span className="px-7">
            <ShouldRender check={onboardingStepsValue === 1}>
              <span>
                <Step1 />
              </span>
            </ShouldRender>
            <ShouldRender check={onboardingStepsValue === 2}>
              <span>
                <PaymentManagement isModal={true} />
              </span>
            </ShouldRender>
            <ShouldRender check={onboardingStepsValue === 3}>
              <span>
                <FinalStep />
              </span>
            </ShouldRender>
          </span>
        </span>

        <ShouldRender check={onboardingStepsValue !== 3}>
          <div
            className={clsx(
              !screens?.md && " fixed bottom-0 left-0 bg-[#222222]",
              "flex w-full gap-x-3.5 border-t-2 border-t-[#303030] py-6 px-7"
            )}
          >
            <ButtonDefault
              size={1}
              variant={ButtonVariants.UNSTYLED}
              roundedSm={true}
              className="w-1/4 p-1 disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-[#303030] disabled:text-textDarkGrayColor disabled:border-0"
              onClick={() => setOnboardingStepsValue(onboardingStepsValue + 1)}
            >
              <span className="flex text-xs  gap-x-2 px-4 font-medium whitespace-nowrap  ">
                Skip
              </span>
            </ButtonDefault>
            <ButtonDefault
              size={1}
              variant={ButtonVariants.UNSTYLED}
              roundedSm={true}
              loading={triggerHandleSubmitStep2Value}
              disabled={triggerHandleSubmitStep2Value}
              onClick={() => {
                if (onboardingStepsValue === 2) {
                  setTriggerHandleSubmitStep2Value(true);
                } else {
                  setOnboardingStepsValue(onboardingStepsValue + 1);
                }
              }}
              className="w-3/4 p-1  disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-dropDownMenuBg disabled:text-[#3E3E3E] disabled:border-0"
            >
              <span className="flex text-xs  gap-x-2 px-4  py-1 font-medium whitespace-nowrap  ">
                Next
              </span>
            </ButtonDefault>
          </div>
        </ShouldRender>
        <ShouldRender check={onboardingStepsValue === 3}>
          <div className="flex w-full justify-center gap-x-3.5 border-t-2 px-7 border-t-[#303030] py-4">
            <ButtonDefault
              size={1}
              variant={ButtonVariants.UNSTYLED}
              roundedSm={true}
              loading={triggerHandleSubmitStep2Value}
              disabled={triggerHandleSubmitStep2Value}
              onClick={() => {
                setOnboardingStepsValue(1);
                handleClose();
                getUserDetails();
              }}
              className="w-full p-1  disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-dropDownMenuBg disabled:text-[#3E3E3E] disabled:border-0"
            >
              <span className="flex text-xs  gap-x-2 px-4  py-1 font-medium whitespace-nowrap  ">
                Done
              </span>
            </ButtonDefault>
          </div>
        </ShouldRender>
      </div>
    </Modal>
  );
};

export default OnboardingModal;
