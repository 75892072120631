import React from "react";
import "./styles/global.scss";
import { Route, Routes, HashRouter } from "react-router-dom";
import Login from "./components/Auth/Login";
import DefaultLayout from "./components/Layout";
import { ConfigProvider, ThemeConfig, theme as antdTheme } from "antd";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useRecoilValue } from "recoil";
import { toggleTheme } from "./atoms/toggleTheme/toggleTheme";
import SignUp from "./components/Auth/SignUp";
import DashboardPage from "./pages/DashboardPage";
import ProfilePage from "./pages/ProfilePage";
import ProductsPage from "./pages/ProductsPage";
import PaymentRequestDetails from "./pages/DashboardPage/PaymentRequestDetails";
import AdminPaymentHistoryDetails from "./components/AdminComp/Dashboard/AdminPaymentHistory/AdminPaymentHistoryDetails";
import ResetPasswordPage from "./pages/reset-password";
import IFramePage from "./pages/IFramePage";

// Define a type for your route paths
type RoutePath =
  | "/"
  | "/signUp"
  | "/dashboard"
  | "/profile-settings"
  | "/products"
  | "/dashboard/:id"
  | "/dashboard/paymentHistory/:id"
  | "/profile-settings/:id"
  | "/reset-password"
  | "/iframe";

// Define a type for the elements that can be rendered within the routes
type RouteElement = React.ReactElement;

// Define an array of route paths where DefaultLayout should not be used
const routesWithoutDefaultLayout: RoutePath[] = [
  "/",
  "/signUp",
  "/reset-password",
];

// Custom function to determine whether to use DefaultLayout
function renderRoute(path: RoutePath, element: RouteElement): RouteElement {
  return routesWithoutDefaultLayout.includes(path) ? (
    element
  ) : (
    <DefaultLayout>{element}</DefaultLayout>
  );
}

const config: ThemeConfig = {
  token: {
    colorPrimary: "#FF5612",
  },
};

function App() {
  const triggerToggleTheme = useRecoilValue(toggleTheme);
  return (
    <ConfigProvider
      theme={{
        ...config,
        algorithm: !triggerToggleTheme
          ? [antdTheme.darkAlgorithm]
          : [antdTheme.defaultAlgorithm],
      }}
    >
      <div className="App ">
        <HashRouter>
          <Routes>
            <Route path="/" element={renderRoute("/", <Login />)} />
            <Route
              path="/signUp"
              element={renderRoute("/signUp", <SignUp />)}
            />
            <Route
              path="/reset-password"
              element={renderRoute("/reset-password", <ResetPasswordPage />)}
            />
            <Route
              path="/dashboard"
              element={renderRoute("/dashboard", <DashboardPage />)}
            />
            <Route
              path="/profile-settings"
              element={renderRoute("/profile-settings", <ProfilePage />)}
            />
            <Route
              path="/products"
              element={renderRoute("/products", <ProductsPage />)}
            />
            <Route
              path="/dashboard/:id"
              element={renderRoute("/dashboard/:id", <PaymentRequestDetails />)}
            />
            <Route
              path="/dashboard/paymentHistory/:id"
              element={renderRoute(
                "/dashboard/paymentHistory/:id",
                <AdminPaymentHistoryDetails />
              )}
            />
            <Route
              path="/profile-settings/:id"
              element={renderRoute("/profile-settings/:id", <ProfilePage />)}
            />
            <Route
              path="/iframe"
              element={renderRoute("/iframe", <IFramePage />)}
            />
          </Routes>
        </HashRouter>
      </div>

      <ToastContainer autoClose={2000} theme={"dark"} />
    </ConfigProvider>
  );
}

export default App;
